import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useFarms, usePriceOurTokenBusd } from '../../../state/hooks'
import { fetchTVLInfo } from '../../../state/farms'
import { getBalanceNumber } from '../../../utils/formatBalance'
import amountInPoolResolver from '../../../utils/amountInPoolHelper'
import usePrices from '../../../hooks/usePrices'
import getPriceFromAmountByPoolId from '../../../utils/getPriceFromAmountByPoolId'
import contracts from '../../../config/constants/contracts'
import Button from '../../../components/Button/Button'
import { ButtonAppearanceEnum } from '../../../components/Button/types'

const Value = styled.div`
  color: rgba(255, 255, 255, 0.95);
  // text-shadow: ${({ theme }) => theme.textShadow} 12px;
  font-weight: 600;
  font-size: 24px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`

const Hint = styled.p`
  color: rgba(255, 255, 255, 0.95);
  font-weight: 300;
    padding: 0;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  text-align: center;
  margin-bottom: 6px;
  
  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes[1]};
  }
`


const TVL = () => {
  const farmsLP = useFarms()

  const prices = usePrices()
  const ourTokenPrice = prices.ourToken;
  const cakePrice = prices.cake;

  const url = `https://pancakeswap.finance/swap?outputCurrency=${contracts.cake['56']}`

  const tvl = useMemo(
    () =>
      farmsLP.reduce((poolSum, farm) => {
        const amountInPool = amountInPoolResolver(farm)
        return poolSum + getBalanceNumber(amountInPool) * (getPriceFromAmountByPoolId(farm, prices))
      }, 0),
    [farmsLP, prices],
  )

  return (
    <div style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
      <Hint>Total Value Locked (TVL)</Hint>
      <Value>${tvl > 0 ? (tvl ).toLocaleString('En') : 0.0}</Value>
      <Button appearance={ButtonAppearanceEnum.primary} url={url} style={{ marginTop: 12}}>
      Buy MMPro
      </Button>
    </div>
  )
}

export default TVL
