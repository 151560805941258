import React, {useContext, useState} from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Link, NavLink } from 'react-router-dom'
import {useWeb3React} from "@web3-react/core";
import logoImg from '../../assets/mmprowhite.png'
import { IHeaderProps } from './types'
import WalletButtonBase from './views/WalletButton'
import TokenPriceBase from './views/TokenPrice'
import BuyButtonBase from './views/BuyButton'
import BuyButtons from '../BuyButtons'
import ConnectModal from "../ConnectModal";
import {ButtonAppearanceEnum} from "../Button/types";
import Button from "../Button/Button";
import Spacer from "../Spacer";


const Block = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 84px;
  margin: 0 auto;
  max-width: 1160px;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px 0 16px;
    flex-wrap: wrap;
    flex-direction: column;
    height: auto;
  }
`

const Logo = styled.div`
  @media (max-width: 767.9px) {
    margin-right: 20px;
    margin-bottom: 10px;
  }
`

const Nav = styled.nav`
  grid-area: nav;
  display: grid;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  column-gap: ${({ theme }) => theme.space[4]};
  @media (max-width: 767.9px) {
    display: none;
  }
`

const WalletButton = styled(WalletButtonBase)`
  //grid-area: wallet-button;
`

const BuyButton = styled(BuyButtonBase)`
  grid-area: buy-button;
`

const TokenPrice = styled(TokenPriceBase)`
  grid-area: cake-price;
`

const CopyButtonWrapper = styled.div`
  padding: 13px;
  background: rgba(255,255,255,0.15);
  border-radius: 5px;
  margin-right: 12px;
  cursor: pointer;
`

const FlexEnd = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20px;
  @media (max-width: 767.9px) {
    margin-bottom: 20px;
    margin-right: 0;
  }
`

const Header = (props: IHeaderProps) => {
  const theme = useContext(ThemeContext)
  const { routes } = props
  const [showLink, setShowLink] = useState(false)
  const [showCopySucs, setShowCopySucs] = useState(false)
  const {account} = useWeb3React()

    const CopyText = `https://farming.marketmaking.pro/ref/${account}`

    function copyToClipboard(textToCopy) {
        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            return navigator.clipboard.writeText(textToCopy);
        }
        
        // text area method
        const textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise(() => {
            document.execCommand('copy');
            textArea.remove();
        });
    }

  return (
    <Block>
      <Logo>
        <a href='https://marketmaking.pro/' target='_blank' rel='noreferrer'>
          <img src={logoImg} alt='MMPRO' style={{height: 50}}/>
        </a>
      </Logo>

    <FlexEnd>
        <a href="https://launchpad.marketmaking.pro">Launchpad</a>
        <Spacer />
        <a href="https://farming.marketmaking.pro">Farming</a>
    </FlexEnd>

      <div style={{display: "flex", alignItems :"center", justifyContent: "flex-end"}}>
          {!showLink && account && !showCopySucs &&
              <Button
                  onClick={
                      () => {
                          setShowLink(true)
                          navigator.clipboard.writeText(CopyText)
                      }
                  }
                  appearance={ButtonAppearanceEnum.primary}
                  style={{marginRight: 10}}
              >
                  Copy Referral
              </Button>
          }
          {showCopySucs && <p style={{marginRight: 10}}> Copied! </p>}
              {showLink && account &&
              <CopyButtonWrapper onClick={ () => {
                  copyToClipboard(CopyText)
                  setShowCopySucs(true)
                  setShowLink(false)
                  setTimeout(() =>
                  {
                      setShowCopySucs(false)
                  }, 2500);
              }
              }>
              <p style={{fontSize: 12}}> {`.../ref/${account}`} </p>
              </CopyButtonWrapper>
          }
          <WalletButtonBase />
      </div>
    </Block>
  )
}

export default Header
