import React from 'react'
import Button from '../../Button'
import { ButtonAppearanceEnum } from '../../Button/types'
import contracts from '../../../config/constants/contracts'

const url = `https://pancakeswap.finance/swap?outputCurrency=${contracts.cake['56']}`

const BuyButton = () => {
  return (
    <Button appearance={ButtonAppearanceEnum.gradient} url={url} style={{ fontSize: 12}}>
      Buy now
    </Button>
  )
}

export default BuyButton
