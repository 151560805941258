import React, { lazy } from 'react'
import { Switch, Route } from 'react-router-dom'
import Header from '../Header'
import Footer from '../Footer'
import { ILayoutProps } from './types'

const   IndexPage = lazy(() => import('../../routes/IndexPage'))

const Layout = (props: ILayoutProps) => {
  const { routes } = props

  return (
    <div>
      <Header routes={routes} />
      <Switch>
          <Route path="/" exact><IndexPage/></Route>
          <Route path="/ref/:referralId?" exact><IndexPage /></Route>
      </Switch>
       <Footer routes={routes} />
    </div>
  )
}

export default Layout
