import React from 'react'
import styled from 'styled-components'
import { Text, InputProps } from '@pancakeswap-libs/uikit'
import useI18n from '../../hooks/useI18n'
import Button from '../Button'
import { ButtonAppearanceEnum } from '../Button/types'

interface ModalInputProps {
  max: string
  symbol: string
  onSelectMax?: () => void
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
  placeholder?: string
  value: string
  addLiquidityUrl?: string
  inputTitle?: string
  pid?: number
}

const getBoxShadow = ({ isWarning = false }) => {
  if (isWarning) {
    return ''
  }

  return ''
}

const StyledTokenInput = styled.div<InputProps>`
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  box-shadow: ${getBoxShadow};
  color: ${({ theme }) => theme.colors.primary};
  padding: 8px 16px 8px 0;
  width: 100%;
  min-width: 300px;
`

const StyledInput = styled.input`
  box-shadow: none;
  width: 100%;
  margin: 0 8px;
  padding: 0 8px;
  background: none;
  border: 0;
  outline: none;
  color: white;
`

const StyledErrorMessage = styled(Text)`
  margin-top: 5px;
  text-align: right;
  a {
    display: inline;
  }
`

const ModalInput: React.FC<ModalInputProps> = ({
  max,
  symbol,
  onChange,
  onSelectMax,
  value,
  addLiquidityUrl,
  inputTitle,
  pid
}) => {
  const TranslateString = useI18n()
  const isBalanceZero = max === '0' || !max

  const displayBalance = isBalanceZero ? '0' : parseFloat(max).toFixed(2)

  return (
    <div style={{ position: 'relative', marginBottom: 15 }}>
      <Text fontSize='14px' style={{ marginBottom: 5, textAlign: 'right' }}>
        {inputTitle && TranslateString(1120, `${inputTitle} balance:`)} {inputTitle &&  displayBalance.toLocaleString()}
      </Text>
      <StyledTokenInput isWarning={isBalanceZero}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <StyledInput style={{fontSize: inputTitle ? '14px' : '10px'}}onChange={onChange} placeholder='0' value={value || ''} />
          {inputTitle && <Button onClick={onSelectMax} appearance={ButtonAppearanceEnum.primary} style={{ padding: '10px 20px' }}>
            {TranslateString(452, 'Max')}
          </Button>}
        </div>
      </StyledTokenInput>
      {isBalanceZero && (
        <StyledErrorMessage fontSize='14px' color='failure'>
          No tokens in wallet:{' '}
          <a href={addLiquidityUrl}>
            {TranslateString(999, 'get')} {symbol}
          </a>
        </StyledErrorMessage>
      )}
    </div>
  )
}

export default ModalInput
