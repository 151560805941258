import { getPancakeProfileAddress, getPancakeRabbitsAddress, getBunnyFactoryAddress } from 'utils/addressHelpers'
import { getContract } from 'utils/web3'
import profileABI from 'config/abi/pancakeProfile.json'
import pancakeRabbitsAbi from 'config/abi/pancakeRabbits.json'
import bunnyFactoryAbi from 'config/abi/bunnyFactory.json'
import erc20ABI from 'config/abi/erc20.json'
import { AbiItem } from 'web3-utils'
import Web3 from 'web3'

export const getProfileContract = () => {
  return getContract(profileABI, getPancakeProfileAddress())
}

export const getPancakeRabbitContract = () => {
  return getContract(pancakeRabbitsAbi, getPancakeRabbitsAddress())
}

export const getBunnyFactoryContract = () => {
  return getContract(bunnyFactoryAbi, getBunnyFactoryAddress())
}

export const getBep20Contract = (address: string, web3?: Web3) => {
  return new web3.eth.Contract((erc20ABI as unknown) as AbiItem, address)
}

export default null
