import { useCallback, useEffect, useState } from 'react'
import { Contract } from 'web3-eth-contract'
import { ethers } from 'ethers'
import { useDispatch } from 'react-redux'
import { fetchFarmUserDataAsync, updateUserAllowance } from 'state/actions'
import { approve, approveERC721 } from 'utils/callHelpers'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { useCake, useERC721, useLottery, useMasterchef, useSousChef } from './useContract'
import farmingContract from '../config/constants/contracts'
import { getMasterChefAddress } from '../utils/addressHelpers'
import { getContract } from '../utils/web3'
import stratxabi from '../config/abi/stratX.json'

// Approve a Farm
export const useApprove = (lpContract: Contract) => {
  const dispatch = useDispatch()
  const { account } = useWeb3React()
  const masterChefContract = useMasterchef()

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(lpContract, masterChefContract, account)
      dispatch(fetchFarmUserDataAsync(account))
      return tx
    } catch (e) {
      return false
    }
  }, [account, dispatch, lpContract, masterChefContract])

  return { onApprove: handleApprove }
}

export const useApproveStable = (lpContract: Contract) => {
  const dispatch = useDispatch()
  const { account } = useWeb3React()
  const masterChefContract = useMasterchef()

  const handleApproveStable = useCallback(async () => {
    try {
      const tx = await approve(lpContract, masterChefContract, account)
      dispatch(fetchFarmUserDataAsync(account))
      return tx
    } catch (e) {
      return false
    }
  }, [account, dispatch, lpContract, masterChefContract])

  return { onApproveStable: handleApproveStable }
}

// Approve a Pool
export const useSousApprove = (lpContract: Contract, sousId) => {
  const dispatch = useDispatch()
  const { account } = useWeb3React()
  const sousChefContract = useSousChef(sousId)

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(lpContract, sousChefContract, account)
      dispatch(updateUserAllowance(sousId, account))
      return tx
    } catch (e) {
      return false
    }
  }, [account, dispatch, lpContract, sousChefContract, sousId])

  return { onApprove: handleApprove }
}

// Approve the lottery
export const useLotteryApprove = () => {
  const { account } = useWeb3React()
  const cakeContract = useCake()
  const lotteryContract = useLottery()

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(cakeContract, lotteryContract, account)
      return tx
    } catch (e) {
      return false
    }
  }, [account, cakeContract, lotteryContract])

  return { onApprove: handleApprove }
}

// Approve an IFO
export const useIfoApprove = (tokenContract: Contract, spenderAddress: string) => {
  const { account } = useWeb3React()
  const onApprove = useCallback(async () => {
    try {
      const tx = await tokenContract.methods
        .approve(spenderAddress, ethers.constants.MaxUint256)
        .send({ from: account })
      return tx
    } catch {
      return false
    }
  }, [account, spenderAddress, tokenContract])

  return onApprove
}

export const useClassicAllowance = (tokenContract: Contract, dependency?: any) => {
  const { account } = useWeb3React()
  const [allowance, setAllowance] = useState(null)
  const spenderAddress = farmingContract.masterChef['56']

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await tokenContract.methods.allowance(account, spenderAddress).call()
        setAllowance(new BigNumber(res))
      } catch (e) {
        setAllowance(null)
      }
    }
    fetch()
  }, [account, spenderAddress, tokenContract, dependency])

  return allowance
}

export const useApproveNft = (token: string) => {
  const { account } = useWeb3React()
  const tokenContract = useERC721(token)
  const masterChefContract = useMasterchef()

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approveERC721(tokenContract, masterChefContract, account)
      return tx
    } catch (e) {
      return false
    }
  }, [account, masterChefContract, tokenContract])

  return { onApproveNFT: handleApprove }
}

export const useGetApprovedNft = (token: string) => {
  const { account } = useWeb3React()
  const [allowance, setAllowance] = useState(null)
  const tokenContract = useERC721(token)
  const masterChef = getMasterChefAddress()

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await tokenContract.methods.isApprovedForAll(account, masterChef).call()
        setAllowance(res)
      } catch (e) {
        setAllowance(null)
      }
    }
    fetch()
  }, [account, tokenContract, token, masterChef])

  return allowance
}

export const useIsLocked = () => {
  const { account } = useWeb3React()
  const [locked, setLocked] = useState(true)
  const contractAddress = '0x52A03897d15a152dB4eD0857573281765f57C0C3'
  const contract = getContract(stratxabi, contractAddress)

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await contract.methods.isLocked(account).call()
        setLocked(res)
      } catch (e) {
        setLocked(true)
      }
    }
    fetch()
  }, [account, contract.methods])

  return locked
}