import React from 'react'
import styled from 'styled-components'
import usePrices from '../../../hooks/usePrices'

const Price = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
`

const TokenPrice = () => {
  const { ourToken } = usePrices()
  return <Price>${ourToken.toNumber().toFixed(5)}</Price>
}

export default TokenPrice
