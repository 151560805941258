import React, { useCallback, useState } from 'react'
import Modal from 'react-modal'
import SVG from 'react-inlinesvg'
import styled, { useTheme } from 'styled-components'
import cancelIcon from 'assets/cancel.svg'

export type ModalParamsType = {
  children: (props: any) => JSX.Element
  props?: any
}

export interface IModalContext {
  openModal: (params: ModalParamsType) => void
  closeModal: () => void
}

export const ModalContext = React.createContext<IModalContext>(null)

const ModalHeader = styled.div`
  display: flex;
`
const Cancel = styled.div`
  margin-left: auto;

  svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
    padding: 8px;

    :hover {
      opacity: ${({ theme }) => theme.opacity};
    }
  }

  path {
    fill: ${({ theme }) => theme.colors.primary} !important;
  }
`
Modal.setAppElement('#root')

const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState(null)
  const theme = useTheme()
  const openModal = useCallback((params: ModalParamsType) => {
    setModal(params)
  }, [])

  const closeModal = useCallback(() => {
    setModal(null)
  }, [])

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      <Modal
        isOpen={!!modal}
        style={{
          overlay: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            inset: 'none',
            background: "radial-gradient(circle, rgb(26, 173, 168) 0%, rgb(24, 119, 147) 45%, rgb(37, 148, 135) 100%)"
        },
          content: {
            border: 'none',
            borderRadius: '20px',
            padding: theme.space[2],
            background: theme.colors.modal,
            width: '50vw',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            inset: 'none',
            minHeight: '590px',
          },
        }}
      >
        <ModalHeader>
          <Cancel onClick={closeModal}>
            <SVG src={cancelIcon} />
          </Cancel>
        </ModalHeader>
        {modal?.children({ ...modal.props, closeModal })}
      </Modal>
    </ModalContext.Provider>
  )
}

export default ModalProvider
