import cakeMasterChefAbi from 'config/abi/cakeMasterChef.json'
import { CAKE_PER_BLOCK } from '../config'

export const abiResolver = (endMasterChefTokenSymbol: string) => {
  if (endMasterChefTokenSymbol && endMasterChefTokenSymbol === 'CAKE') {
    return cakeMasterChefAbi
  }
  return null
}

export const rewardPerBlockResolver = (endMasterChefTokenSymbol: string) => {
  if (endMasterChefTokenSymbol && endMasterChefTokenSymbol === 'CAKE') {
    return CAKE_PER_BLOCK
  }
  return null
}
