import React, { useState } from 'react'
import styled from 'styled-components'
import CardActionsContainer from 'components/FarmCard/CardActionsContainer'
import { IFarmCardDetailedProps } from '../types'
import Button from '../../../components/Button/Button'
import { getBalanceNumber } from '../../../utils/formatBalance'
import { useHarvest, useHarvestWithdraw } from '../../../hooks/useHarvest'
import { ButtonAppearanceEnum } from '../../../components/Button/types'

const Block = styled.div`
  min-height: 200px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding: ${({ theme }) => theme.space[1]} 0;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`

const Column = styled.div`
  width: calc(50% - 10px);
  flex: 0 0 auto;
  display: grid;
  row-gap: ${({ theme }) => theme.space[1]};
  grid-template-rows: repeat(5, 40px);
  grid-template-columns: 80px 1fr;
  align-items: center;
  //background: rgba(255,255,255, 0.3);
  border: 1px solid rgba(255,255,255,0.3);
  padding: 10px;
  border-radius: 10px;
  padding-left: 20px;

  :first-child {
    display: block;
    //border-right: 1px solid rgba(255, 255, 255, 0.3);
    margin-right: 10px;
    padding-left: 0;
    width: 50%;
  }

  @media (max-width: 768px) {
    :first-child {
      margin-top: 16px;
      border-right: 0;
      width: 100%;
    }
    width: 100%;
    row-gap: ${({ theme }) => theme.space[0]};
  }
`

const Earned = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: space-between;
  }
`

const Wallet = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`

const Balance = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: auto;

  & > span:first-child {
    margin-bottom: ${({ theme }) => theme.space[1]};
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: 600;
    font-size: ${({ theme }) => theme.fontSizes[2]};
  }

  & > span:last-child {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 700;
    font-size: ${({ theme }) => theme.fontSizes[5]};
  }
`
const Head = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
  opacity: 0.8;

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes[0]};
  }
`

const Value = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes[1]};
  }
`

const Link = styled.a`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: 700;
  text-decoration: underline;

  :hover {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
  }
`

const FarmCardDetailed: React.FC<IFarmCardDetailedProps> = ({
  APY,
  DAILY,
  bscScan,
  stakedBalance,
  rawEarningsBalance,
  account,
  // tokenBalance,
  priceFromAmountByPoolId,
  addLiquidityUrl,
  farm,
  earningsInUsd, depositLimit,
  tvl, rawDoubleEarnings, earningsInUsdDouble
}) => {
  const [pendingTx, setPendingTx] = useState(false)
  const tokenName = farm.lpSymbol
  const stakedBalanceString = `${getBalanceNumber(stakedBalance).toFixed(2)} ${tokenName}`
  const { onReward } = useHarvest(farm.pid)

  return (
    <Block>
      <Column>
        <Wallet>
          <Balance>
            <CardActionsContainer
                tvl={tvl}
                depositLimit={depositLimit}
                farm={farm} account={account}
                addLiquidityUrl={addLiquidityUrl} />
          </Balance>
        </Wallet>
      </Column>
      <Column>
        <Head>Staked</Head>
        <Value>{stakedBalanceString}</Value>
        <Head>Staked USD</Head>
        <Value>{(getBalanceNumber(stakedBalance) * priceFromAmountByPoolId).toFixed(2)} USD</Value>
        { farm.doubleFarmActive &&
        <>
          <Head>Earned {farm.doubleName}</Head>
          <Earned>
           <Value style={{ marginRight: 15 }}>
             {rawDoubleEarnings.toFixed(2)} {farm.doubleName}
             
             { /*(${earningsInUsdDouble.toFixed(2)})*/ }
           </Value>
           {
             !farm.mmproActive &&
             <Button
                 disabled={rawEarningsBalance === 0 || pendingTx}
                 appearance={ButtonAppearanceEnum.primary}
                 onClick={async () => {
                   setPendingTx(true)
                   await onReward()
                   setPendingTx(false)
                 }}
             >
               Harvest
             </Button>
          }
          </Earned>
        </>
        }
        {
          farm.mmproActive &&
              <>
                <Head>Earned</Head>
                <Earned>
                  <Value style={{ marginRight: 15 }}>
                    {rawEarningsBalance.toFixed(2)} MMPRO (${earningsInUsd.toFixed(2)})
                  </Value>
                  <Button
                      disabled={rawEarningsBalance === 0 || pendingTx}
                      appearance={ButtonAppearanceEnum.primary}
                      onClick={async () => {
                        setPendingTx(true)
                        await onReward()
                        setPendingTx(false)
                      }}
                  >
                    Harvest
                  </Button>
                </Earned>
              </>
        }

        <Head>Earn</Head>
          <Value> {farm.mmproActive ? "MMPRO" : ""} {farm.doubleFarmActive && farm.mmproActive && "+"} {farm.doubleFarmActive && `${farm.doubleName}`}</Value>
      </Column>
    </Block>
  )
}

export default FarmCardDetailed
