import React from 'react'
import BigNumber from 'bignumber.js'
import {
  usePriceBnbBusd,
  usePriceBtcBusd,
  usePriceCakeBusd,
  usePriceEthBusd,
  usePriceOurTokenBusd, usePriceStbuMmpro,
  usePriceTensetUsd,
} from '../state/hooks'

export interface IPricesContext {
  bnb?: number
  eth?: number
  ourToken?: BigNumber
  cake?: BigNumber
  btc?: number
  tenset?: number
  stbu?: number
}

export const PricesContext = React.createContext<IPricesContext>({})

const PricesContextProvider = ({ children }) => {
  const bnb = usePriceBnbBusd()
  const eth = usePriceEthBusd()
  const btc = usePriceBtcBusd()
  const tenset = usePriceTensetUsd()
  const ourToken = usePriceOurTokenBusd()
  const cake = usePriceCakeBusd(bnb)
  const stbu = usePriceStbuMmpro()

  return <PricesContext.Provider value={{ bnb, eth, ourToken, cake, btc, tenset, stbu}}>{children}</PricesContext.Provider>
}

export default PricesContextProvider
