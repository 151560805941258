import React from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { IWalletCardProps } from '../types'
import { connectorLocalStorageKey } from '../../../config/constants/wallets'

const Block = styled.div`
  display: grid;
  padding: 8px;
  border-radius: ${({ theme }) => theme.borderRadius};
  grid-auto-flow: column;
  grid-template-columns: auto 1fr;
  cursor: pointer;
  height: 56px;
  align-items: center;
  column-gap: ${({ theme }) => theme.space[1]};

  :hover {
    background-color: rgba(229, 229, 229, 0.35);
  }
`

const Name = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: 700;
`

const WalletCard: React.FC<IWalletCardProps> = (props) => {
  const { title, icon, connectorId, closeModal, login } = props

  return (
    <Block
      onClick={() => {
        login(connectorId)
        window.localStorage.setItem(connectorLocalStorageKey, connectorId)
        closeModal()
      }}
    >
      <SVG src={icon} />
      <Name>{title}</Name>
    </Block>
  )
}

export default WalletCard
