import BigNumber from 'bignumber.js'
import React, { useCallback, useMemo, useState } from 'react'
import ModalInput from 'components/ModalInput'
import useI18n from 'hooks/useI18n'
import { getFullDisplayBalance } from 'utils/formatBalance'
import Button from 'components/Button'
import { ButtonAppearanceEnum } from './Button/types'
import { getAllowance } from '../utils/erc20'
import { useClassicAllowance, useIsLocked } from '../hooks/useApprove'

interface ReferralModalProps {
  tokenName?: string
  pid?: number
    refValue: string
    setRef: any
}

const ReferralModal: React.FC<ReferralModalProps> = ({ tokenName = '' , pid,refValue, setRef}) => {

  const handleSelectMax = useCallback(() => {
    setRef(localStorage.getItem("referral"))
  }, [ setRef])

  return (
    <div>
      <ModalInput
        value={refValue}
        onSelectMax={handleSelectMax}
        onChange={setRef}
        max={localStorage.getItem("referral")}
        symbol={tokenName}
        inputTitle=''
        pid={pid}
      />
    </div>
  )
}

export default ReferralModal
