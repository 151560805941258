import metamaskIcon from 'assets/wallets/metamask.svg'
import trustWalletIcon from 'assets/wallets/trust-wallet.svg'
import mathWalletIcon from 'assets/wallets/math-wallet.svg'
import tokenPocketIcon from 'assets/wallets/token-pocket.svg'
import walletConnectIcon from 'assets/wallets/wallet-connect.svg'
import binanceChainIcon from 'assets/wallets/binance-chain-wallet.svg'
import { WalletConfig } from './types'

export const connectorLocalStorageKey = 'connectorId'

export enum ConnectorNames {
  Injected = 'injected',
  WalletConnect = 'walletconnect',
  BSC = 'bsc',
}

const wallets: WalletConfig[] = [
  {
    title: 'Metamask',
    icon: metamaskIcon,
    connectorId: ConnectorNames.Injected,
  },
  {
    title: 'TrustWallet',
    icon: trustWalletIcon,
    connectorId: ConnectorNames.Injected,
  },
  {
    title: 'MathWallet',
    icon: mathWalletIcon,
    connectorId: ConnectorNames.Injected,
  },
  {
    title: 'TokenPocket',
    icon: tokenPocketIcon,
    connectorId: ConnectorNames.Injected,
  },
  {
    title: 'WalletConnect',
    icon: walletConnectIcon,
    connectorId: ConnectorNames.WalletConnect,
  },
  {
    title: 'Binance Chain Wallet',
    icon: binanceChainIcon,
    connectorId: ConnectorNames.BSC,
  },
]

export default wallets
