import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

interface SpacerProps {
  size?: 'sm' | 'md' | 'lg'
}

const Spacer: React.FC<SpacerProps> = ({ size = 'md' }) => {
  const { space } = useContext(ThemeContext)

  let s: string
  switch (size) {
    case 'lg':
      s = space[6]
      break
    case 'sm':
      s = space[2]
      break
    case 'md':
    default:
      s = space[4]
  }

  return <StyledSpacer size={s} />
}

interface StyledSpacerProps {
  size: string
}

const StyledSpacer = styled.div<StyledSpacerProps>`
  height: ${(props) => props.size};
  width: ${(props) => props.size};
`

export default Spacer
