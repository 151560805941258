import React, {useEffect} from 'react'
import styled from 'styled-components'
import BigNumber from "bignumber.js";
import { useTotalSupply } from 'hooks/useTokenBalance'
import numeral from 'numeral'
import {useParams} from "react-router-dom";
import TVL from './views/TVL'
import FarmsList from './views/FarmsList'
import { getBalanceNumber } from '../../utils/formatBalance'
import usePrices from "../../hooks/usePrices";


const Title = styled.p`
  color: ${({theme}) => theme.colors.primary};
  font-weight: 600;
  margin: 0;  
  padding: 0;
  font-size: 60px;
  margin-bottom: 6px;
  text-align: right;

  @media (max-width: 768px) {
    font-size: 30px;
    text-align: center;
    width: 100%;
  }
  
  @keyframes animationText {
    0% {
      color: ${({theme}) => theme.colors.primary};
    }
    50% {
      background: linear-gradient(286deg, #E02FEE 23%, #4cfc98 76%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    100% {
      color: ${({theme}) => theme.colors.primary};
    }
  }
`

const TitleNoAnimation = styled.p<TitleProps>`
  color: white;
  font-weight: 600;
  margin: 0;
  padding: 0;
  font-size: 60px;
  margin-bottom: 12px;
  text-align: left;
  width: 800px;

  @media (max-width: 768px) {
    font-size: 30px;
    text-align: center;
    width: 390px;
  }
`

interface TitleProps {
  background?: boolean
}

const MainPageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
  margin-top: 30px;
  max-width: 1400px;
  width: 100%;
`

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  max-width: 1400px;
  width: 100%;
  margin-bottom: 100px;
  margin-top: 25px;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }
  
`

const StatisticsWrapper = styled.div`
  background: rgba(0,0,0,0.2);
  border-radius: 5px;
  width: 350px;
  margin-left: 50px;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-left: 0;
  }
`

const StatisticsWrapperMini = styled.div`
  background: rgba(0,0,0,0.2);
  border-radius: 5px;
  width: 350px;
  margin-left: 50px;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: linear;

  @media (max-width: 768px) {
    margin-left: 0;
  }
  
  h1{
    font-size: 16px;
    font-weight: 300;
    color: rgba(255,255,255,0.65);
    margin-left: 5px;
    margin-right: 5px;
  }
  p {
    font-size: 16px;
    color: #fff;
  }
`

 const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
   flex-direction: column;
`

const FlexCenterRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
   flex-direction: row;
`

const IndexPage: React.FC = () => {

  const ourToken  = usePrices().ourToken
  const tokenSupply = getBalanceNumber(useTotalSupply())
  const params: any = useParams()


  // add ref address to LocalStorage
  const isAddress = function (referralId) {
    return /^(0x)?[0-9a-f]{40}$/i.test(referralId);
  };

  useEffect(() => {
      if (!localStorage.getItem("referral")) {
        if (isAddress(params.referralId)) {
          localStorage.setItem("referral", params.referralId);
        } else localStorage.setItem("referral", "0x381e583B4EfA91c347D84C6C8369151411a50676");
      }
  }, [params])


  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}>
      <MainPageWrapper>
        <TopWrapper>
          <div>
            <Title > Farm on</Title>
            <Title > Market Making Pro</Title>
          </div>
          <div>
            <StatisticsWrapper style={{height: 120}}>
              <TVL />
            </StatisticsWrapper>
            <StatisticsWrapperMini>
              <h1> MMPRO price</h1>
              <FlexCenterRow>
                <p> {ourToken.toNumber().toFixed(4)}</p>
                <h1 > USD </h1>
              </FlexCenterRow>
            </StatisticsWrapperMini>
            <StatisticsWrapperMini>
              <h1> MMPRO marketcap</h1>
              <FlexCenterRow>
                <p> {numeral((ourToken.toNumber() * (tokenSupply))).format('0.0a')}</p>
                <h1 > USD </h1>
              </FlexCenterRow>
            </StatisticsWrapperMini>
            <StatisticsWrapperMini>
              <h1> MMPRO supply</h1>
              <FlexCenterRow>
                <p> {numeral(tokenSupply).format('0.00a')}</p>
                <h1 > MMPRO </h1>
              </FlexCenterRow>
            </StatisticsWrapperMini>
          </div>
        </TopWrapper>

        <FlexCenter>
          <TitleNoAnimation background> FARMING POOLS</TitleNoAnimation>
        </FlexCenter>
        <FarmsList />
      </MainPageWrapper>
    </div>
  )
}

export default IndexPage
