import BigNumber from 'bignumber.js/bignumber'
import { Farm } from '../state/types'
import { BIG_ZERO } from './formatBalance'

const amountInPoolResolver = (farm: Farm) => {
  if (farm.isOurPool) {
    return farm.userData && farm.userData.ourContractBalances ? farm.userData.ourContractBalances : BIG_ZERO
  }
  return farm.userData && farm.userData.contractBalances ? farm.userData.contractBalances : BIG_ZERO
}

export default amountInPoolResolver
