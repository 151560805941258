import React, {useCallback, useState} from 'react'
import BigNumber from 'bignumber.js'
import {provider} from 'web3-core'
import {getAddress} from 'utils/addressHelpers'
import {Farm} from 'state/types'
import StakeAction from './StakeAction'
import {getBep20Contract} from '../../utils/contractHelpers'
import useWeb3 from '../../hooks/useWeb3'
import useModal from '../../hooks/useModal'
import {BIG_ZERO} from '../../utils/formatBalance'
import ConnectModal from '../ConnectModal'
import {ButtonAppearanceEnum} from '../Button/types'
import Button from '../Button'
import useAuth from '../../hooks/useAuth'

// const Action = styled.div`
//   padding: 8px;
//   display: flex;
//   align-items: start;
//   justify-content: space-between;
//   background: #394454;
//   border-radius: 8px;
// `

export interface FarmWithStakedValue extends Farm {
    apy?: BigNumber
}

interface FarmCardActionsProps {
    farm: FarmWithStakedValue
    ethereum?: provider
    account?: string
    addLiquidityUrl?: string
    depositLimit: number
  tvl?: number
}

const CardActions: React.FC<FarmCardActionsProps> = ({farm, account,
                                                       addLiquidityUrl, depositLimit, tvl}) => {

    const pid = farm.pid
    const lpAddresses = farm.lpAddresses
    const stakedBalance = farm.userData && farm.userData.stakedBalance ? farm.userData.stakedBalance : BIG_ZERO
    const lpAddress = getAddress(lpAddresses)
    const lpName = farm.lpSymbol.toUpperCase()
    const tokenBalance = new BigNumber((farm && farm.userData && farm.userData.tokenBalance) || 0)
    const web3 = useWeb3()

    const {openModal} = useModal()
    const {login} = useAuth()

    const lpContract = getBep20Contract(lpAddress, web3)
    const allowance = new BigNumber((farm && farm.userData && farm.userData.allowance) || 0)
    const isApproved = account && allowance.toNumber() > 0


    const renderApprovalOrStakeButton = () => {
        return (
            <StakeAction
                stakedBalance={stakedBalance}
                tokenBalance={tokenBalance}
                tokenName={lpName}
                pid={pid}
                addLiquidityUrl={addLiquidityUrl}
                isApproved={isApproved}
                lpContract={lpContract}
                depositLimit={depositLimit}
                tvl={tvl}
            />
        )
    }

    return (
        <div>
            {!account ? (
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", minHeight: 225}}>
                    <Button
                        onClick={() =>
                            openModal({
                                children: ConnectModal,
                                props: {login},
                            })
                        }
                        appearance={ButtonAppearanceEnum.primary}
                    >
                        Connect Wallet
                    </Button>
                </div>
            ) : (
                renderApprovalOrStakeButton()
            )}
        </div>
    )
}

export default CardActions
