import { getBalanceNumber } from './formatBalance'
import { IPricesContext } from '../contexts/PricesContext'
import {useTokenBalanceOnContract} from "../hooks/useTokenBalance";

const getPriceDoubleToken = (farm, prices: IPricesContext): number => {
  if (!farm.doubleFarmActive) {
    return 0
  }

  const { userData, quoteTokenSymbolDouble } = farm
  const amountValueByPoolId = userData && quoteTokenSymbolDouble && getBalanceNumber(userData.lpTokenValueDoubleQuote) / getBalanceNumber(userData.lpTokenValueDouble)

  switch (quoteTokenSymbolDouble) {
    case 'BUSD':
      return amountValueByPoolId
    case 'BNB':
      return amountValueByPoolId * prices.bnb
    case 'MMPRO':
      return amountValueByPoolId * prices.ourToken.toNumber()
    default:
      return amountValueByPoolId * prices.eth
  }
}

export default getPriceDoubleToken
