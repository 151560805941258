export default {
  cake: {
    97: '0x246423476B215C16dB5f63D77ee49F5c5B7fea61',
    56: '0x6067490d05f3cf2fdffc0e353b1f5fd6e5ccdf70',
  },
  syrup: {
    97: '0xcb5924a9214ea5b84f3f8a87a76e7ca7f1652e27',
    56: '0xcb5924a9214ea5b84f3f8a87a76e7ca7f1652e27',
  },
  masterChef: {
    97: '0xE35c3A83406ce02FE0108748491D7Bc7147BE9A7',
    56: '0xE35c3A83406ce02FE0108748491D7Bc7147BE9A7',
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  wbnb: {
    97: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  },
  daovc: {
    97: '0xab6b429c73c22ecabc763635dace7efac524993c',
    56: '0xab6b429c73c22ecabc763635dace7efac524993c',
  },
  lottery: {
    97: '0x99c2EcD51d52c036B00130d882Bc65f20Fdecf9f',
    56: '0x3C3f2049cc17C136a604bE23cF7E42745edf3b91',
  },
  lotteryNFT: {
    97: '0x8175c10383511b3a1C68f9dB222dc14A19CC950e',
    56: '0x5e74094Cd416f55179DBd0E45b1a8ED030e396A1',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '0x77eA4310c21B234D8e9d5f2c5974994de33f71e6',
  },
  ust: {
    56: '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
    97: '',
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeRabbits: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    56: '0x73feaa1ee314f8c655e354234017be2193c9e24e',
    97: '0x73feaa1ee314f8c655e354234017be2193c9e24e',
  },
  eth: {
    56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    97: '',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  usdc: {
    56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    97: '',
  },
  usdt: {
    56: '0x55d398326f99059ff775485246999027b3197955',
    97: '',
  },
  dai: {
    56: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
    97: '',
  },
}
