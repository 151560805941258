import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { useDispatch } from 'react-redux'
import BigNumber from 'bignumber.js'
import Spinner from 'react-svg-spinner'
import { useFarms } from '../../../state/hooks'
import useRefresh from '../../../hooks/useRefresh'
import { fetchFarmUserDataAsync } from '../../../state/farms'
import FarmCard from './FarmCard'
import { AUTO_PER_BLOCK, BLOCKS_PER_YEAR } from '../../../config'
import { QuoteToken } from '../../../config/constants/types'
import usePrices from '../../../hooks/usePrices'
import Button from '../../../components/Button'
import { ButtonAppearanceEnum } from '../../../components/Button/types'

const Loader = styled.div`
  height: 35px;
  text-align: center;
`

const Block = styled.div`
  display: grid;
  grid-auto-flow: row;
  row-gap: ${({ theme }) => theme.space[1]};
  margin: ${({ theme }) => theme.space[3]} auto 128px;
  max-width: 800px;
  width: 100%;
  padding: ${({ theme }) => theme.space[1]};
  justify-content: center;
  border-radius: 15px;

`

const FarmsList: React.FC = () => {
  const farmsLP = useFarms()
  const { bnb: bnbPrice, ourToken: ourTokenPrice } = usePrices()
  const { account } = useWeb3React()

  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    if (account) {
      dispatch(fetchFarmUserDataAsync(account))
    }
  }, [account, dispatch, slowRefresh])

  // const activeFarms = useMemo(() => farmsLP.filter((farm) =>  farm.multiplier !== '0X'
  // ), [farmsLP])

  const activeFarms = farmsLP;

  const farmsList = useMemo(
    () =>
      activeFarms.map((farm) => {
        if (!farm.tokenAmount || !farm.lpTotalInQuoteToken || !farm.lpTotalInQuoteToken) {
          return farm
        }
        const cakeRewardPerBlock = AUTO_PER_BLOCK.times(farm.poolWeight)
        const cakeRewardPerYear = cakeRewardPerBlock.times(BLOCKS_PER_YEAR)

        let apy = ourTokenPrice.times(cakeRewardPerYear).div(farm.lpTotalInQuoteToken)
        if (farm.quoteTokenSymbol === QuoteToken.BUSD || farm.quoteTokenSymbol === QuoteToken.UST) {
          apy = ourTokenPrice.times(cakeRewardPerYear).div(farm.lpTotalInQuoteToken).times(bnbPrice)
        } else if (farm.quoteTokenSymbol === QuoteToken.CAKE) {
          apy = cakeRewardPerYear.div(farm.lpTotalInQuoteToken)
        } else if (farm.dual) {
          const cakeApy =
            farm && ourTokenPrice.times(cakeRewardPerBlock).times(BLOCKS_PER_YEAR).div(farm.lpTotalInQuoteToken)
          const dualApy =
            farm.tokenPriceVsQuote &&
            new BigNumber(farm.tokenPriceVsQuote)
              .times(farm.dual.rewardPerBlock)
              .times(BLOCKS_PER_YEAR)
              .div(farm.lpTotalInQuoteToken)
          apy = cakeApy && dualApy && cakeApy.plus(dualApy)
        }

        return { ...farm, apy }
      }),
    [activeFarms, bnbPrice, ourTokenPrice],
  )

  return (
    <Block>
      {farmsList.map((farm) => (
        <FarmCard key={farm.pid} farm={farm} removed={false} account={account} />
      ))}
    </Block>
  )
}

export default FarmsList
