import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { fetchFarmUserDataAsync, updateUserBalance, updateUserStakedBalance } from 'state/actions'
import { placeERC721onMarket, sousStake, sousStakeBnb, stake, stakeWithMint, swap } from 'utils/callHelpers'
import { useWeb3React } from '@web3-react/core'
import checksum from 'eth-checksum'
import { useMasterchef, useSousChef } from './useContract'


const useStake = (pid: number, referralValue: string) => {
  const dispatch = useDispatch()
  const { account } = useWeb3React()
  const masterChefContract = useMasterchef()

    const isAddress = function (referralId) {
        return /^(0x)?[0-9a-f]{40}$/i.test(referralId);
    };

  const handleStake = useCallback(
    async (amount: string) => {

        let newRefValue = referralValue;
        if (!isAddress(referralValue)) {
            newRefValue = "0x381e583B4EfA91c347D84C6C8369151411a50676"
        }
        const checksummed = checksum.encode(newRefValue)

      const txHash = await stake(masterChefContract, pid, amount, account, checksummed)
      dispatch(fetchFarmUserDataAsync(account))
      console.info(txHash)
    },
    [account, dispatch, masterChefContract, pid, referralValue],
  )

  return { onStake: handleStake }
}




export const useSousStake = (sousId, isUsingBnb = false) => {
  const dispatch = useDispatch()
  const { account } = useWeb3React()
  const masterChefContract = useMasterchef()
  const sousChefContract = useSousChef(sousId)

  const handleStake = useCallback(
    async (amount: string) => {
      if (sousId === 0) {
        await stake(masterChefContract, 0, amount, account, "1")
      } else if (isUsingBnb) {
        await sousStakeBnb(sousChefContract, amount, account)
      } else {
        await sousStake(sousChefContract, amount, account)
      }
      dispatch(updateUserStakedBalance(sousId, account))
      dispatch(updateUserBalance(sousId, account))
    },
    [account, dispatch, isUsingBnb, masterChefContract, sousChefContract, sousId],
  )

  return { onStake: handleStake }
}

export default useStake
