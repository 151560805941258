import { createGlobalStyle } from 'styled-components'
import BgImage from '../assets/1080.png'

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
    line-height: 1.219;
  }

  html {
    font-size: ${({theme}) => theme.fontSizes[3]};
  }

  body {
    background: radial-gradient(circle, #32bb81 0%, #2980a4 45%, #181933 100%);
    // background-image: url(${BgImage});
    color: ${({theme}) => theme.colors.primary};

    img {
      height: auto;
    }

    a {
      color: ${({theme}) => theme.colors.primary};
      font-size: ${({theme}) => theme.fontSizes[2]};
      font-weight: 700;
      transition: color ${({theme}) => theme.transition};
      text-decoration: none;

      :hover {
        color: ${({theme}) => theme.colors.accent};
      }
    }
  }
`

export default GlobalStyle
