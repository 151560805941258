import React, { useCallback, useMemo, useState } from 'react'
import numeral from 'numeral'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { IFarmCardProps } from '../types'
import FarmCardHeader from './FarmCardHeader'
import FarmCardDetailed from './FarmCardDetailed'
import usePrices from '../../../hooks/usePrices'
import amountInPoolResolver from '../../../utils/amountInPoolHelper'
import { BIG_ZERO, getBalanceNumber } from '../../../utils/formatBalance'
import { AUTO_PER_BLOCK, BASE_ADD_LIQUIDITY_URL, BLOCKS_PER_YEAR } from '../../../config'
import useClientApy from '../../../hooks/useClientApy'
import getPriceFromAmountByPoolId from '../../../utils/getPriceFromAmountByPoolId'
import getLiquidityUrlPathParts from '../../../utils/getLiquidityUrlPathParts'
import contracts from '../../../config/constants/contracts'
import { getAddress } from '../../../utils/addressHelpers'
import getPriceDoubleToken from "../../../utils/getPriceDoubleToken";
import {DA} from "../../../config/localisation/languageCodes";

const Block = styled.div`
  //border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 0 ${({ theme }) => theme.space[2]};
  background: rgba(0,0,0,0.3);
  width: 800px;

  @media (max-width: 768px) {
    width: 390px;
  }
`

const FarmCard: React.FC<IFarmCardProps> = (props) => {
  const { farm } = props

  const [isCardOpened, setCardOpened] = useState(false)
  const toggleCard = useCallback(() => setCardOpened(!isCardOpened), [isCardOpened])

  const { account } = useWeb3React()
  const prices = usePrices()
  const ourTokenPrice = prices.ourToken.toNumber();

  const amountInPool = amountInPoolResolver(farm)
  const priceFromAmountByPoolId = useMemo(() => getPriceFromAmountByPoolId(farm, prices), [farm, prices])

  const cakeRewardPerBlock = AUTO_PER_BLOCK.times(farm.poolWeight)
  const cakeRewardPerYear = cakeRewardPerBlock.times(BLOCKS_PER_YEAR)

  const tokenBalance = new BigNumber((farm && farm.userData && farm.userData.tokenBalance) || BIG_ZERO)
  const stakedBalance = new BigNumber((farm && farm.userData && farm.userData.stakedBalance) || BIG_ZERO)

  const doubleTokenPriceGet = useMemo(() => getPriceDoubleToken(farm, prices), [farm, prices])
  const doubleTokenPrice = farm.doubleFarmActive ? doubleTokenPriceGet : 0
  const stakedTotalUsd = getBalanceNumber(amountInPool) * (priceFromAmountByPoolId )
  const APRinDoubleCoin = farm && farm && farm.doublePerBlock && farm.poolWeight &&
      (100 * getBalanceNumber(farm.doublePerBlock) * new BigNumber(farm.poolWeight).toNumber() * doubleTokenPrice * BLOCKS_PER_YEAR.toNumber())

  let APR = useMemo(
    () =>
      numeral(cakeRewardPerYear.times(new BigNumber(
          (100 * ourTokenPrice) / stakedTotalUsd))
          .toNumber() + (farm.doubleFarmActive ? APRinDoubleCoin : 0) / stakedTotalUsd,
      ).format('0.0a'),
    [APRinDoubleCoin, cakeRewardPerYear, ourTokenPrice, farm.doubleFarmActive, stakedTotalUsd],
  )

  // console.log(`Pool: ${farm.pid}, APR: ${APR}%, APRinDoubleCoin: ${(APRinDoubleCoin/stakedTotalUsd).toFixed(2)}%`)

  let DAILY = useMemo(
    () =>
      numeral(
          (cakeRewardPerYear
              .times(new BigNumber((100 * ourTokenPrice) / stakedTotalUsd))
              .toNumber() + (farm.doubleFarmActive ? APRinDoubleCoin : 0) / stakedTotalUsd)
          / 365,
      ).format('0.0a'),
    [ APRinDoubleCoin, cakeRewardPerYear, ourTokenPrice, stakedTotalUsd, farm.doubleFarmActive],
  )

  if (farm.isArchived) {
    APR = '- ';
    DAILY = '- ';
  }

  const earnings = farm.userData ? new BigNumber(farm.userData.earnings) : BIG_ZERO
  const doubleEarnings = farm.doubleFarmActive && farm.userData ? new BigNumber(farm.userData.doubleEarnings) : BIG_ZERO
  const rawEarningsBalance = getBalanceNumber(earnings)
  const rawDoubleEarnings = doubleEarnings && getBalanceNumber(doubleEarnings)
  const earningsInUsd = rawEarningsBalance * ourTokenPrice
  const earningsInUsdDouble = rawDoubleEarnings * doubleTokenPrice

  const { quoteTokenAdresses, quoteTokenSymbol, tokenAddresses } = farm
  const liquidityUrlPathParts = getLiquidityUrlPathParts({ quoteTokenAdresses, quoteTokenSymbol, tokenAddresses })
  const addLiquidityUrl = `${BASE_ADD_LIQUIDITY_URL}/${liquidityUrlPathParts}`
  const depositLimit = farm.depositLimit * (priceFromAmountByPoolId)
  const tvl = getBalanceNumber(amountInPool) * (priceFromAmountByPoolId)

  return (
    <Block>
      <FarmCardHeader
        label={farm.lpSymbol}
        TVL={tvl}
        APY={APR}
        DAILY={DAILY}
        toggleCard={toggleCard}
        isCardOpened={isCardOpened}
        priceFromAmountByPoolId={priceFromAmountByPoolId}
        stakedBalance={stakedBalance}
        pid={farm.pid}
        depositLimit={depositLimit}
      />
      {isCardOpened && (
        <FarmCardDetailed
          APY={APR}
          DAILY={DAILY}
          bscScan={farm.lpAddresses[process.env.REACT_APP_CHAIN_ID]}
          stakedBalance={stakedBalance}
          rawEarningsBalance={rawEarningsBalance}
          account={account}
          tokenBalance={tokenBalance}
          priceFromAmountByPoolId={priceFromAmountByPoolId}
          addLiquidityUrl={addLiquidityUrl}
          farm={farm}
          earningsInUsd={earningsInUsd}
          depositLimit={depositLimit}
          tvl={tvl}
          rawDoubleEarnings={rawDoubleEarnings}
          earningsInUsdDouble={earningsInUsdDouble}
        />
      )}
    </Block>
  )
}

export default FarmCard
