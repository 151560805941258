import { DefaultTheme } from 'styled-components'
import { ButtonAppearanceEnum } from '../components/Button/types'

const ThemeStyle: DefaultTheme = {
  fontSizes: ['10px', '12px', '14px', '16px', '18px', '20px'],
  space: ['10px', '16px', '24px', '32px', '48px', '64px'],
  colors: {
    layout: 'linear-gradient(359.19deg, rgb(32, 18, 62) -8.64%, rgb(17, 5, 32) 46.83%) rgb(15, 14, 26)',
    layout2: 'linear-gradient(359.19deg,rgb(18 34 62) -8.64%,rgb(5 11 32) 46.83%) rgb(15,14,26)',
    primary: 'rgb(255, 255, 255)',
    secondary: 'rgba(255, 255, 255, 0.7)',
    accent: 'rgb(17,243,130)',
    dark: 'rgb(0, 0, 0)',
    light: 'rgb(229, 229, 229)',
    footer: 'rgb(15, 14, 26)',
    catalog: 'rgb(15, 14, 26)',
  },
  button: {
    [ButtonAppearanceEnum.primary]: {
      color: 'rgb(255,255,255)',
      background: '#33cc66',
      fontSize: '14px',
      padding: '10px',
      activeBackground: '#21e061',
      border: "none"
    },
    [ButtonAppearanceEnum.secondary]: {
      color: 'rgba(255, 255, 255, 0.9)',
      background: 'rgba(0,0,0, 1)',
      fontSize: '14px',
      padding: '8px',
      activeBackground: 'rgba(0,0,0, 0.5)',
      border: "none"
    },
    [ButtonAppearanceEnum.gradient]: {
      color: 'rgb(255, 255, 255)',
      background: 'linear-gradient(286deg,#4CA0FC 23%,#07f69a 76%)',
      fontSize: '14px',
      padding: '8px 12px',
      border: "none",
      fontSizeActive: '15px'

    },
  },
  transition: 'ease 0.15s',
  borderRadius: '16px',
  textShadow: 'rgb(0 151 255) 0px 0px',
  opacity: '0.64',
}

export default ThemeStyle
