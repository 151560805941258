import React from 'react'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'
import { IFooterProps } from './types'
import Button from '../Button/Button'
import { ISocialLink } from '../SocialLinks/types'
import telegram from '../../assets/social-icons/telegram.svg'
import twitter from '../../assets/social-icons/twitter.svg'

const Block = styled.footer`
  background: none;
  padding: 0 ${({ theme }) => theme.space[0]};
`

const Nav = styled.nav`
  height: 56px;
  max-width: 1160px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
    background: none;
  }

  path {
    fill: #fff};
  }

  a {
    color: ${({ theme }) => theme.colors.secondary};

    :hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`

const socialLinks: ISocialLink[] = [
  {
    id: 'telegram',
    icon: telegram,
    url: 'https://t.me/market_making_pro_eng',
  },
  {
    id: 'twitter',
    icon: twitter,
    url: 'https://twitter.com/MarketmakingPro',
  },
]

const Footer = (props: IFooterProps) => {
  const { routes } = props

  return (
    <Block>
      <Nav>
        {socialLinks.map((link) => (
          <Button key={link.id} url={link.url} style={{ margin: 15, background: "none" }}>
            <SVG src={link.icon} />
          </Button>
        ))}
      </Nav>
      <br />
      <div style={{ alignContent: "center", textAlign: "center" }}>
        v. 1.0.3
      </div>

    </Block>
  )
}

export default Footer
