import React from 'react'
import IndexPage from './IndexPage'

export interface IRoute {
  id: string
  path: string
  exact: boolean
  component: React.FC
  label: string
  visible?: boolean
}

const ROOT_ROUTE = 'root'

const routes: IRoute[] = [
  {
    id: ROOT_ROUTE,
    path: '/',
    exact: true,
    component: IndexPage,
    label: 'Optimizer',
  },
]

export default routes
