import contracts from './contracts'
import {FarmConfig, QuoteToken} from './types'
import {getAddress} from '../../utils/addressHelpers'

// Use it only for OUR pool. Only for except errors. Don`t use this value in counting
const STUB_END_MASTERCHEF_ADDRESS = getAddress(contracts.masterChef)
const STUB_END_MASTERCHEF_PID = 0

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'MMPRO-BUSD LP',
    lpAddresses: {
      97: '0x656aa2eaa2f0ef876686c76c36a010e0f4d1e4a3',
      56: '0x656aa2eaa2f0ef876686c76c36a010e0f4d1e4a3',
    },
    tokenSymbol: 'MMPRO-BUSD LP',
    tokenAddresses: {
      97: getAddress(contracts.cake),
      56: getAddress(contracts.cake),
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    isOurPool: true,
    endMasterChefAddress: STUB_END_MASTERCHEF_ADDRESS,
    pidOfEndMasterChefAddress: STUB_END_MASTERCHEF_PID,
    endMasterChefTokenSymbol: null,
    stratAddress: getAddress(contracts.masterChef),
    doubleFarmActive: false,
    mmproActive: true,
    doubleToken: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    doubleTokenLp: "0x656aa2eaa2f0ef876686c76c36a010e0f4d1e4a3",
    quoteTokenSymbolDouble: QuoteToken.BUSD,
    quoteTokenAdressesDouble: contracts.busd,
    doubleName: "BONUS",
    depositLimit: 467500,
    isArchived: false,
  },
  {
    pid: 1,
    lpSymbol: 'MMPRO-WBNB LP',
    lpAddresses: {
      97: '0x8fd7b2858c6624c4ce51e931d9368d0cecfdcfde',
      56: '0x8fd7b2858c6624c4ce51e931d9368d0cecfdcfde',
    },
    tokenSymbol: 'MMPRO-WBNB LP',
    tokenAddresses: {
      97: getAddress(contracts.cake),
      56: getAddress(contracts.cake),
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    isOurPool: true,
    endMasterChefAddress: STUB_END_MASTERCHEF_ADDRESS,
    pidOfEndMasterChefAddress: STUB_END_MASTERCHEF_PID,
    endMasterChefTokenSymbol: null,
    stratAddress: getAddress(contracts.masterChef),
    doubleFarmActive: false,
    mmproActive: true,
    doubleToken: "0x656aa2eaa2f0ef876686c76c36a010e0f4d1e4a3",
    doubleTokenLp: "0xe0b9bc86ba88f53dc92d4a19a6c33f135e6bcd43",
    quoteTokenSymbolDouble: QuoteToken.BUSD,
    quoteTokenAdressesDouble: contracts.busd,
    doubleName: "BONUS",
    depositLimit: 19600,
    isArchived: false,
  },
  // {
  //   pid: 2,
  //   lpSymbol: 'MMPRO-10SET LP',
  //   lpAddresses: {
  //     97: '0x27118fe8ea1a158816c74e7a99c1418aff7aa9c7',
  //     56: '0x27118fe8ea1a158816c74e7a99c1418aff7aa9c7',
  //   },
  //   tokenSymbol: 'MMPRO-10SET LP',
  //   tokenAddresses: {
  //     97: "0x1f64fdad335ed784898effb5ce22d54d8f432523",
  //     56: "0x1f64fdad335ed784898effb5ce22d54d8f432523",
  //   },
  //   quoteTokenSymbol: QuoteToken.MMPRO,
  //   quoteTokenAdresses: contracts.cake,
  //   isOurPool: true,
  //   endMasterChefAddress: STUB_END_MASTERCHEF_ADDRESS,
  //   pidOfEndMasterChefAddress: STUB_END_MASTERCHEF_PID,
  //   endMasterChefTokenSymbol: null,
  //   stratAddress: getAddress(contracts.masterChef),
  //   doubleFarmActive: true,
  //   mmproActive: true,
  //   doubleToken: "0x1f64fdad335ed784898effb5ce22d54d8f432523",
  //   doubleTokenLp: "0x71a99d49e6c0322d20a292cbf0a32ee5b54ace13",
  //   quoteTokenSymbolDouble: QuoteToken.BNB,
  //   quoteTokenAdressesDouble: contracts.wbnb,
  //   doubleName: "10SET",
  //   depositLimit: 200000,
  //   isArchived: false,
  // },

  // {
  //   pid: 4,
  //   lpSymbol: 'MMPRO-DESU LP',
  //   lpAddresses: {
  //     97: '0xb12ef7C82f663e5FF79034362D16e3ED93caBBa5',
  //     56: '0xb12ef7C82f663e5FF79034362D16e3ED93caBBa5',
  //   },
  //   tokenSymbol: 'MMPRO-DESU LP',
  //   tokenAddresses: {
  //     97: "0x32f1518BaAcE69e85b9E5fF844eBd617c52573ac",
  //     56: "0x32f1518BaAcE69e85b9E5fF844eBd617c52573ac",
  //   },
  //   quoteTokenSymbol: QuoteToken.MMPRO,
  //   quoteTokenAdresses: contracts.cake,
  //   isOurPool: true,
  //   endMasterChefAddress: STUB_END_MASTERCHEF_ADDRESS,
  //   pidOfEndMasterChefAddress: STUB_END_MASTERCHEF_PID,
  //   endMasterChefTokenSymbol: null,
  //   stratAddress: getAddress(contracts.masterChef),
  //   doubleFarmActive: true,
  //   mmproActive: true,
  //   doubleToken: "0x32f1518BaAcE69e85b9E5fF844eBd617c52573ac",
  //   doubleTokenLp: "0xb12ef7C82f663e5FF79034362D16e3ED93caBBa5",
  //   quoteTokenSymbolDouble: QuoteToken.MMPRO,
  //   quoteTokenAdressesDouble: contracts.cake,
  //   doubleName: "DESU",
  //   depositLimit: 900000,
  //   isArchived: false,
  // },
  // {
  //   pid: 6,
  //   lpSymbol: 'DESU-BUSD',
  //   lpAddresses: {
  //     97: '0xc0fc8ad7e7d61e63ed57c8fb727f7e50b98778ef',
  //     56: '0xc0fc8ad7e7d61e63ed57c8fb727f7e50b98778ef',
  //   },
  //   tokenSymbol: 'DESU-BUSD',
  //   tokenAddresses: {
  //     97: "0x32f1518BaAcE69e85b9E5fF844eBd617c52573ac",
  //     56: "0x32f1518BaAcE69e85b9E5fF844eBd617c52573ac",
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  //   isOurPool: true,
  //   endMasterChefAddress: STUB_END_MASTERCHEF_ADDRESS,
  //   pidOfEndMasterChefAddress: STUB_END_MASTERCHEF_PID,
  //   endMasterChefTokenSymbol: null,
  //   stratAddress: getAddress(contracts.masterChef),
  //   doubleFarmActive: true,
  //   mmproActive: false,
  //   doubleToken: "0x32f1518BaAcE69e85b9E5fF844eBd617c52573ac",
  //   doubleTokenLp: "0xc0fc8ad7e7d61e63ed57c8fb727f7e50b98778ef",
  //   quoteTokenSymbolDouble: QuoteToken.BUSD,
  //   quoteTokenAdressesDouble: contracts.busd,
  //   doubleName: "DESU",
  //   depositLimit: 900000,
  //   isArchived: false,
  // },
  // {
  //   pid: 7,
  //   lpSymbol: 'DAOvc-BUSD',
  //   lpAddresses: {
  //     97: '0x2e00f20d9ae33a216817946f4177b02bd3b29e97',
  //     56: '0x2e00f20d9ae33a216817946f4177b02bd3b29e97',
  //   },
  //   tokenSymbol: 'DAOvc-BUSD',
  //   tokenAddresses: {
  //     97: "0xab6b429c73c22ecabc763635dace7efac524993c",
  //     56: "0xab6b429c73c22ecabc763635dace7efac524993c",
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  //   isOurPool: true,
  //   endMasterChefAddress: STUB_END_MASTERCHEF_ADDRESS,
  //   pidOfEndMasterChefAddress: STUB_END_MASTERCHEF_PID,
  //   endMasterChefTokenSymbol: null,
  //   stratAddress: getAddress(contracts.masterChef),
  //   doubleFarmActive: true,
  //   mmproActive: false,
  //   doubleToken: "0xab6b429c73c22ecabc763635dace7efac524993c",
  //   doubleTokenLp: "0x2e00f20d9ae33a216817946f4177b02bd3b29e97",
  //   quoteTokenSymbolDouble: QuoteToken.BUSD,
  //   quoteTokenAdressesDouble: contracts.busd,
  //   doubleName: "DAOvc",
  //   depositLimit: 900000,
  //   isArchived: false,
  // },

  // {
  //   pid: 8,
  //   lpSymbol: 'MMPRO-DAOvc LP',
  //   lpAddresses: {
  //     97: '0xBcd6a90cfd95FC587D21876eb6B933A2765Ea1fe',
  //     56: '0xBcd6a90cfd95FC587D21876eb6B933A2765Ea1fe',
  //   },
  //   tokenSymbol: 'MMPRO-DAOvc LP',
  //   tokenAddresses: {
  //     97: "0xab6b429c73c22ecabc763635dace7efac524993c",
  //     56: "0xab6b429c73c22ecabc763635dace7efac524993c",
  //   },
  //   quoteTokenSymbol: QuoteToken.MMPRO,
  //   quoteTokenAdresses: contracts.cake,
  //   isOurPool: true,
  //   endMasterChefAddress: STUB_END_MASTERCHEF_ADDRESS,
  //   pidOfEndMasterChefAddress: STUB_END_MASTERCHEF_PID,
  //   endMasterChefTokenSymbol: null,
  //   stratAddress: getAddress(contracts.masterChef),
  //   doubleFarmActive: true,
  //   mmproActive: true,
  //   doubleToken: "0xab6b429c73c22ecabc763635dace7efac524993c",
  //   doubleTokenLp: "0xBcd6a90cfd95FC587D21876eb6B933A2765Ea1fe",
  //   quoteTokenSymbolDouble: QuoteToken.MMPRO,
  //   quoteTokenAdressesDouble: contracts.cake,
  //   doubleName: "DAOvc",
  //   depositLimit: 200000,
  //   isArchived: false,
  // },

  // {
  //   pid: 13,
  //   lpSymbol: 'THC-BUSD',
  //   lpAddresses: {
  //     97: '0x315D54F05668115Fc3fb9510E0898F5DC1CB4D2F',
  //     56: '0x315D54F05668115Fc3fb9510E0898F5DC1CB4D2F',
  //   },
  //   tokenSymbol: 'THC-BUSD',
  //   tokenAddresses: {
  //     97: "0x56083560594E314b5cDd1680eC6a493bb851BBd8",
  //     56: "0x56083560594E314b5cDd1680eC6a493bb851BBd8",
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  //   isOurPool: true,
  //   endMasterChefAddress: STUB_END_MASTERCHEF_ADDRESS,
  //   pidOfEndMasterChefAddress: STUB_END_MASTERCHEF_PID,
  //   endMasterChefTokenSymbol: null,
  //   stratAddress: getAddress(contracts.masterChef),
  //   doubleFarmActive: true,
  //   mmproActive: false,
  //   doubleToken: "0x56083560594E314b5cDd1680eC6a493bb851BBd8",
  //   doubleTokenLp: "0x315D54F05668115Fc3fb9510E0898F5DC1CB4D2F",
  //   quoteTokenSymbolDouble: QuoteToken.BUSD,
  //   quoteTokenAdressesDouble: contracts.busd,
  //   doubleName: "THC",
  //   depositLimit: 900000,
  //   isArchived: true,
  // },

  // {
  //   pid: 12,
  //   lpSymbol: 'CPD-BUSD [archived]',
  //   lpAddresses: {
  //     97: '0x25d81Bc89d77ebf63F31121647803D95420Be7C2',
  //     56: '0x25d81Bc89d77ebf63F31121647803D95420Be7C2',
  //   },
  //   tokenSymbol: 'CPD-BUSD [archived]',
  //   tokenAddresses: {
  //     97: "0x2406dce4da5ab125a18295f4fb9fd36a0f7879a2",
  //     56: "0x2406dce4da5ab125a18295f4fb9fd36a0f7879a2",
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  //   isOurPool: true,
  //   endMasterChefAddress: STUB_END_MASTERCHEF_ADDRESS,
  //   pidOfEndMasterChefAddress: STUB_END_MASTERCHEF_PID,
  //   endMasterChefTokenSymbol: null,
  //   stratAddress: getAddress(contracts.masterChef),
  //   doubleFarmActive: true,
  //   mmproActive: false,
  //   doubleToken: "0x2406dce4da5ab125a18295f4fb9fd36a0f7879a2",
  //   doubleTokenLp: "0x25d81Bc89d77ebf63F31121647803D95420Be7C2",
  //   quoteTokenSymbolDouble: QuoteToken.BUSD,
  //   quoteTokenAdressesDouble: contracts.busd,
  //   doubleName: "CPD",
  //   depositLimit: 900000,
  //   isArchived: true,
  // },
  //
  //
  //
  // {
  //   pid: 11,
  //   lpSymbol: 'CPD-MMPRO [archived]',
  //   lpAddresses: {
  //     97: '0xbaf124F83db363883e4d9884E516612f3AAEfD33',
  //     56: '0xbaf124F83db363883e4d9884E516612f3AAEfD33',
  //   },
  //   tokenSymbol: 'CPD-MMPRO [archived]',
  //   tokenAddresses: {
  //     97: "0x2406dce4da5ab125a18295f4fb9fd36a0f7879a2",
  //     56: "0x2406dce4da5ab125a18295f4fb9fd36a0f7879a2",
  //   },
  //   quoteTokenSymbol: QuoteToken.MMPRO,
  //   quoteTokenAdresses: contracts.cake,
  //   isOurPool: true,
  //   endMasterChefAddress: STUB_END_MASTERCHEF_ADDRESS,
  //   pidOfEndMasterChefAddress: STUB_END_MASTERCHEF_PID,
  //   endMasterChefTokenSymbol: null,
  //   stratAddress: getAddress(contracts.masterChef),
  //   doubleFarmActive: true,
  //   mmproActive: true,
  //   doubleToken: "0x2406dce4da5ab125a18295f4fb9fd36a0f7879a2",
  //   doubleTokenLp: "0xbaf124F83db363883e4d9884E516612f3AAEfD33",
  //   quoteTokenSymbolDouble: QuoteToken.MMPRO,
  //   quoteTokenAdressesDouble: contracts.cake,
  //   doubleName: "CPD",
  //   depositLimit: 900000,
  //   isArchived: true,
  // },
  //
  // {
  //   pid: 10,
  //   lpSymbol: 'CPD-BUSD [archived]',
  //   lpAddresses: {
  //     97: '0x25d81Bc89d77ebf63F31121647803D95420Be7C2',
  //     56: '0x25d81Bc89d77ebf63F31121647803D95420Be7C2',
  //   },
  //   tokenSymbol: 'CPD-BUSD [archived]',
  //   tokenAddresses: {
  //     97: "0x2406dce4da5ab125a18295f4fb9fd36a0f7879a2",
  //     56: "0x2406dce4da5ab125a18295f4fb9fd36a0f7879a2",
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  //   isOurPool: true,
  //   endMasterChefAddress: STUB_END_MASTERCHEF_ADDRESS,
  //   pidOfEndMasterChefAddress: STUB_END_MASTERCHEF_PID,
  //   endMasterChefTokenSymbol: null,
  //   stratAddress: getAddress(contracts.masterChef),
  //   doubleFarmActive: true,
  //   mmproActive: false,
  //   doubleToken: "0x2406dce4da5ab125a18295f4fb9fd36a0f7879a2",
  //   doubleTokenLp: "0x25d81Bc89d77ebf63F31121647803D95420Be7C2",
  //   quoteTokenSymbolDouble: QuoteToken.BUSD,
  //   quoteTokenAdressesDouble: contracts.busd,
  //   doubleName: "CPD",
  //   depositLimit: 900000,
  //   isArchived: true,
  // },



  // {
  //   pid: 3,
  //   lpSymbol: 'MMPRO-STBU LP [OLD ARCHIVED]',
  //   lpAddresses: {
  //     97: '0xe0b9bc86ba88f53dc92d4a19a6c33f135e6bcd43',
  //     56: '0xe0b9bc86ba88f53dc92d4a19a6c33f135e6bcd43',
  //   },
  //   tokenSymbol: 'MMPRO-STBU LP [OLD ARCHIVED]',
  //   tokenAddresses: {
  //     97: "0x52ad4d6329ca7d4bb31ae859ede4bf60116a5483",
  //     56: "0x52ad4d6329ca7d4bb31ae859ede4bf60116a5483",
  //   },
  //   quoteTokenSymbol: QuoteToken.MMPRO,
  //   quoteTokenAdresses: contracts.cake,
  //   isOurPool: true,
  //   endMasterChefAddress: STUB_END_MASTERCHEF_ADDRESS,
  //   pidOfEndMasterChefAddress: STUB_END_MASTERCHEF_PID,
  //   endMasterChefTokenSymbol: null,
  //   stratAddress: getAddress(contracts.masterChef),
  //   doubleFarmActive: true,
  //   mmproActive: true,
  //   doubleToken: "0x52ad4d6329ca7d4bb31ae859ede4bf60116a5483",
  //   doubleTokenLp: "0xe0b9bc86ba88f53dc92d4a19a6c33f135e6bcd43",
  //   quoteTokenSymbolDouble: QuoteToken.MMPRO,
  //   quoteTokenAdressesDouble: contracts.cake,
  //   doubleName: "STBU",
  //   depositLimit: 900000,
  //   isArchived: true
  // },

  //
  // {
  //   pid: 9,
  //   lpSymbol: 'DAOvc-WBNB LP',
  //   lpAddresses: {
  //     97: '0xD29553a1946c9FC39Ab01948173c8A84ed93A692',
  //     56: '0xD29553a1946c9FC39Ab01948173c8A84ed93A692',
  //   },
  //   tokenSymbol: 'DAOvc-WBNB LP',
  //   tokenAddresses: {
  //     97: '0xaB6B429C73C22EcAbC763635DAce7efAC524993c',
  //     56: '0xaB6B429C73C22EcAbC763635DAce7efAC524993c'
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  //   isOurPool: true,
  //   endMasterChefAddress: STUB_END_MASTERCHEF_ADDRESS,
  //   pidOfEndMasterChefAddress: STUB_END_MASTERCHEF_PID,
  //   endMasterChefTokenSymbol: null,
  //   stratAddress: getAddress(contracts.masterChef),
  //   doubleFarmActive: true,
  //   mmproActive: false,
  //   doubleToken: "0xaB6B429C73C22EcAbC763635DAce7efAC524993c",
  //   doubleTokenLp: "0xD29553a1946c9FC39Ab01948173c8A84ed93A692",
  //   quoteTokenSymbolDouble: QuoteToken.BNB,
  //   quoteTokenAdressesDouble: contracts.wbnb,
  //   doubleName: "DAOvc",
  //   depositLimit: 19600
  // },



  // {
  //   pid: 5,
  //   lpSymbol: 'DESU-BUSD [OLD ARCHIVED]',
  //   lpAddresses: {
  //     97: '0xc0fc8ad7e7d61e63ed57c8fb727f7e50b98778ef',
  //     56: '0xc0fc8ad7e7d61e63ed57c8fb727f7e50b98778ef',
  //   },
  //   tokenSymbol: 'DESU-BUSD [OLD ARCHIVED]',
  //   tokenAddresses: {
  //     97: "0x32f1518BaAcE69e85b9E5fF844eBd617c52573ac",
  //     56: "0x32f1518BaAcE69e85b9E5fF844eBd617c52573ac",
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  //   isOurPool: true,
  //   endMasterChefAddress: STUB_END_MASTERCHEF_ADDRESS,
  //   pidOfEndMasterChefAddress: STUB_END_MASTERCHEF_PID,
  //   endMasterChefTokenSymbol: null,
  //   stratAddress: getAddress(contracts.masterChef),
  //   doubleFarmActive: false,
  //   mmproActive: false,
  //   doubleToken: "0x32f1518BaAcE69e85b9E5fF844eBd617c52573ac",
  //   doubleTokenLp: "0xb12ef7C82f663e5FF79034362D16e3ED93caBBa5",
  //   quoteTokenSymbolDouble: QuoteToken.DESU,
  //   quoteTokenAdressesDouble: contracts.cake,
  //   doubleName: "DESU",
  //   depositLimit: 900000
  // },
]

export default farms
