/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import farmsConfig from 'config/constants/farms'
import fetchFarms from './fetchFarms'
import {
  fetchFarmDoubleTokenInLp,
  fetchFarmEndMasterChefAddressInLpContractAddress,
  fetchFarmLPTokenInValue, fetchFarmLPTokenInValueDoubleQuote,
  fetchFarmUserAllowances,
  fetchFarmUserEarnings, fetchFarmUserEarningsDouble,
  fetchFarmUserStakedBalances,
  fetchFarmUserStakedBalancesOnCake,
  fetchFarmUserTokenBalances,
  fetchOurPoolContractBalances,
  fetchTotalLPSupply, fetchTotalLPSupplyDouble,
} from './fetchFarmUser'
import { Farm, FarmsState } from '../types'

const initialState: FarmsState = { data: [...farmsConfig] }

export const farmsSlice = createSlice({
  name: 'Farms',
  initialState,
  reducers: {
    setFarmsPublicData: (state, action) => {
      const liveFarmsData: Farm[] = action.payload
      state.data = state.data.map((farm) => {
        const liveFarmData = liveFarmsData.find((f) => f.pid === farm.pid)
        return { ...farm, ...liveFarmData }
      })
    },
    setFarmUserData: (state, action) => {
      const { arrayOfUserDataObjects } = action.payload
      arrayOfUserDataObjects.forEach((userDataEl) => {
        const { index } = userDataEl
        state.data[index] = { ...state.data[index], userData: userDataEl }
      })
    },
    setTvlInfo: (state, action) => {
      const { arrayOfTVLInfo } = action.payload
      arrayOfTVLInfo.forEach((tvlInfo) => {
        const { index } = tvlInfo
        state.data[index] = { ...state.data[index], userData: tvlInfo }
      })
    },
  },
})

// Actions
export const { setFarmsPublicData, setFarmUserData, setTvlInfo } = farmsSlice.actions

// Thunks
export const fetchFarmsPublicDataAsync = () => async (dispatch) => {
  const farms = await fetchFarms()
  dispatch(setFarmsPublicData(farms))
}

export const fetchFarmUserDataAsync = (account) => async (dispatch) => {
  const userFarmAllowances = await fetchFarmUserAllowances(account)
  const userFarmTokenBalances = await fetchFarmUserTokenBalances(account)
  const userStakedBalances = await fetchFarmUserStakedBalances(account)
  const userFarmEarnings = await fetchFarmUserEarnings(account)
  const userFarmDoubleEarnings = await fetchFarmUserEarningsDouble(account)
  const contractBalances = await fetchFarmUserStakedBalancesOnCake()
  const ourContractBalances = await fetchOurPoolContractBalances()
  const endMasterChefInLp = await fetchFarmEndMasterChefAddressInLpContractAddress()
  const lpTokenValue = await fetchFarmLPTokenInValue()
  const totalLPSupply = await fetchTotalLPSupply()
  const lpTokenValueDoubleQuote = await fetchFarmLPTokenInValueDoubleQuote()
  const lpTokenValueDouble = await fetchFarmDoubleTokenInLp()

  const arrayOfUserDataObjects = userFarmAllowances.map((farmAllowance, index) => {
    return {
      index,
      allowance: userFarmAllowances[index],
      tokenBalance: userFarmTokenBalances[index],
      stakedBalance: userStakedBalances[index],
      earnings: userFarmEarnings[index],
      doubleEarnings: userFarmDoubleEarnings[index],
      contractBalances: contractBalances[index],
      ourContractBalances: ourContractBalances[index],
      endMasterChefInLp: endMasterChefInLp[index],
      lpTokenValue: lpTokenValue[index],
      totalLPSupply: totalLPSupply[index],
      lpTokenValueDoubleQuote: lpTokenValueDoubleQuote[index],
      lpTokenValueDouble: lpTokenValueDouble[index],
    }
  })

  dispatch(setFarmUserData({ arrayOfUserDataObjects }))
}

export const fetchTVLInfo = () => async (dispatch) => {
  const contractBalances = await fetchFarmUserStakedBalancesOnCake()
  const lpTokenValue = await fetchFarmLPTokenInValue()
  const totalLPSupply = await fetchTotalLPSupply()
  const ourContractBalances = await fetchOurPoolContractBalances()
  const endMasterChefInLp = await fetchFarmEndMasterChefAddressInLpContractAddress()
  const lpTokenValueDoubleQuote = await fetchFarmLPTokenInValueDoubleQuote()
  const lpTokenValueDouble = await fetchFarmDoubleTokenInLp()


  const arrayOfTVLInfo = contractBalances.map((contractBalance, index) => {
    return {
      index,
      contractBalances: contractBalances[index],
      ourContractBalances: ourContractBalances[index],
      lpTokenValue: lpTokenValue[index],
      totalLPSupply: totalLPSupply[index],
      endMasterChefInLp: endMasterChefInLp[index],
      lpTokenValueDoubleQuote: lpTokenValueDoubleQuote[index],
      lpTokenValueDouble: lpTokenValueDouble[index],
    }
  })

  dispatch(setTvlInfo({ arrayOfTVLInfo }))
}

export default farmsSlice.reducer
