import React, { Suspense, useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { useFetchPublicData } from 'state/hooks'
import { useDispatch } from 'react-redux'
import GlobalStyle from './style/Global'
import PageLoader from './components/PageLoader'
import useEagerConnect from './hooks/useEagerConnect'
import routes from './routes'
import Layout from './components/Layout'
import ResetStyle from './style/Reset'
import { fetchTVLInfo } from './state/farms'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page'

// eslint-disable-next-line @typescript-eslint/no-unused-vars

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  // Monkey patch warn() because of web3 flood
  // To be removed when web3 1.3.5 is released
  useEffect(() => {
    console.warn = () => null
  }, [])

  useEagerConnect()
  useFetchPublicData()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTVLInfo())
  }, [dispatch])

  return (
    <Router>
      <ResetStyle />
      <GlobalStyle />
      <Suspense fallback={<PageLoader />}>
        <Layout routes={routes} />
      </Suspense>
    </Router>
  )
}

export default React.memo(App)
