import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import useUnstake from 'hooks/useUnstake'
import useStake from 'hooks/useStake'
// import { getBalanceNumber } from 'utils/formatBalance'
import DepositModal from '../DepositModal'
import WithdrawModal from '../WithdrawModal'
import ReferralModal from '../ReferralModal'
import Spacer from '../Spacer'

interface FarmCardActionsProps {
  stakedBalance?: BigNumber
  tokenBalance?: BigNumber
  tokenName?: string
  pid?: number
  addLiquidityUrl?: string
  isApproved?: boolean
  lpContract?: any
  userStableBalance?: any
  userStableAllowance?: any
    depositLimit: number
    tvl: number
}

interface DepositWithdrawButtonsProps {
  depositModal?: boolean
}

const DepositWithdrawButtons = styled.p<DepositWithdrawButtonsProps>`
  cursor: pointer;
  color: #fff;
  border: ${(props) => (props.depositModal ? '1px solid #34cc66' : 'none')};
  opacity: ${(props) => (props.depositModal ? 1 : 0.66)};
  border-radius: 12px;
  padding: 5px 15px;
`

const StakeAction: React.FC<FarmCardActionsProps> = ({
                                                       stakedBalance,
                                                       tokenBalance,
                                                       tokenName,
                                                       pid,
                                                       addLiquidityUrl,
                                                       isApproved,
                                                       lpContract,
                                                       userStableBalance,
                                                       userStableAllowance,
                                                         depositLimit,
                                                         tvl
                                                     }) => {
  const [depositModal, setDepositModal] = useState(1)

    const [referralValue, setReferralValue] = useState(localStorage.getItem("referral"))
    const handleChangeRef = useCallback(
        (e: React.FormEvent<HTMLInputElement>) => {
            setReferralValue(e.currentTarget.value)
        },
        [setReferralValue],
    )

    const { onStake } = useStake(pid, referralValue)
    const { onUnstake } = useUnstake(pid)

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
        <DepositWithdrawButtons onClick={() => setDepositModal(1)} depositModal={depositModal === 1}>
          Deposit
        </DepositWithdrawButtons>
        <DepositWithdrawButtons onClick={() => setDepositModal(2)} depositModal={depositModal === 2}>
          Withdraw
        </DepositWithdrawButtons>
          <DepositWithdrawButtons onClick={() => setDepositModal(3)} depositModal={depositModal === 3}>
              Referral
          </DepositWithdrawButtons>
      </div>
      <Spacer size='sm' />
        {depositModal === 1 &&
            <DepositModal tvl={tvl} depositLimit={depositLimit} pid={pid} max={tokenBalance} onConfirm={onStake} tokenName={tokenName}
                addLiquidityUrl={addLiquidityUrl} isApproved={isApproved} lpContract={lpContract}/>
        }
      {depositModal === 2 && <WithdrawModal max={stakedBalance} onConfirm={onUnstake} tokenName={tokenName} pid={pid}/>}
      {depositModal === 3 && <ReferralModal refValue={referralValue} setRef={handleChangeRef} tokenName={tokenName} pid={pid}/>}
      <Spacer size='md' />
    </div>
  )
}

export default StakeAction
